<template>
  <v-text-field
    v-if="inputType == 'string'"
    v-bind="$attrs"
    color="primary accent-4"
    persistent-placeholder
    :value="inputValue"
    @input="$emit('update:inputValue', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.textRules"
    :disabled="disabled"
    :readonly="readonly"
    :dense="dense"
    @keypress="formatInput($event)"
  ></v-text-field>

  <v-text-field
    v-else-if="inputType == 'number'"
    v-bind="$attrs"
    hide-spin-buttons
    color="primary accent-4"
    persistent-placeholder
    :value="inputValue"
    @input="$emit('update:inputValue', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.numberRules"
    :disabled="disabled"
    type="number"
    :readonly="readonly"
    :dense="dense"
    @keypress="formatInput($event)"
  ></v-text-field>

  <v-text-field
    v-else-if="inputType == 'email'"
    v-bind="$attrs"
    color="primary accent-4"
    persistent-placeholder
    :value="inputValue"
    @input="$emit('update:inputValue', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.emailRules"
    :disabled="disabled"
    type="email"
    :readonly="readonly"
    :dense="dense"
    @keypress="formatInput($event)"
  ></v-text-field>

  <v-select
    v-else-if="inputType == 'select'"
    v-bind="$attrs"
    color="primary accent-4"
    persistent-placeholder
    :items="options"
    :value="inputValue"
    @input="$emit('update:inputValue', $event)"
    item-text="text"
    item-value="value"
    :label="label"
    outlined
    :placeholder="placeHolder"
    hide-details="auto"
    :disabled="disabled"
    no-data-text="沒有資料"
    :readonly="readonly"
    :rules="rules.textRules"
    :dense="dense"
    :menu-props="{ bottom: true, offsetY: true }"
    @change="$emit('selectChange', $event)"
  ></v-select>

  <v-dialog v-else-if="inputType == 'dateRangePicker'" ref="dateRangeModal" v-model="menu" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        color="primary accent-4"
        persistent-placeholder
        :label="label"
        :placeholder="placeHolder"
        :append-icon="icons.mdiCalendar"
        :disabled="disabled"
        :value="displayCustomDateRange(selectDateRange)"
        outlined
        hide-details="auto"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :rules="rules.dateRangeRules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectDateRange"
      color="primary accent-4"
      @input="$emit('pickerInput', $func.orderDateArrInput($event))"
      range
      no-title
      scrollable
      :first-day-of-week="0"
      :day-format="$Formatter.dayFormat"
      locale="zh-hk"
      :disabled="disabled"
      :min="minDay"
      :max="maxDay"
    >
      <v-btn
        text
        small
        color="error"
        v-if="showClearBtn"
        @click="
          $refs.dateRangeModal.save([])
          $emit('update:inputValue', $func.orderDateArrInput([]))
          $emit('dateRangeDialog', 'clear')
        "
      >
        清除
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        small
        color="primary"
        @click="
          menu = false
          selectDateRange = saveSelectDateRange
          $emit('dateRangeDialog', 'cancel')
        "
      >
        取消
      </v-btn>
      <v-btn
        text
        small
        color="success"
        @click="
          $refs.dateRangeModal.save(selectDateRange)
          $emit('update:inputValue', $func.orderDateArrInput(selectDateRange))
          $emit('dateRangeDialog', 'confirm')
        "
      >
        確定
      </v-btn>
    </v-date-picker>
  </v-dialog>

  <v-dialog v-else-if="inputType == 'datePicker'" ref="dateModal" v-model="menu" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        color="primary accent-4"
        persistent-placeholder
        :label="label"
        :placeholder="placeHolder"
        :append-icon="icons.mdiCalendar"
        :disabled="disabled"
        :value="selectDate"
        outlined
        hide-details="auto"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :rules="rules.dateRules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectDate"
      color="primary accent-4"
      @input="$emit('pickerInput', $event)"
      no-title
      scrollable
      :first-day-of-week="0"
      :day-format="$Formatter.dayFormat"
      locale="zh-hk"
      :disabled="disabled"
      :min="minDay"
      :max="maxDay"
    >
      <v-btn
        text
        small
        color="error"
        v-if="showClearBtn"
        @click="
          $refs.dateModal.save('')
          $emit('update:inputValue', '')
          $emit('dateDialog', 'clear')
        "
      >
        清除
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        small
        color="primary"
        @click="
          menu = false
          selectDate = saveSelectDate
          $emit('dateDialog', 'cancel')
        "
      >
        取消
      </v-btn>
      <v-btn
        text
        small
        color="success"
        @click="
          $refs.dateModal.save(selectDate)
          $emit('update:inputValue', selectDate)
          $emit('dateDialog', 'confirm')
        "
      >
        確定
      </v-btn>
    </v-date-picker>
  </v-dialog>

  <CustomDigitalTimepicker
    v-else-if="inputType == 'timePicker'"
    :label="label"
    :time.sync="inputTime"
    :required="required"
    :disableMenu="loading"
  ></CustomDigitalTimepicker>

  <v-textarea
    v-else-if="inputType == 'textarea'"
    color="primary accent-4"
    persistent-placeholder
    :value="inputValue"
    @input="$emit('update:inputValue', $event)"
    :label="label"
    :placeholder="placeHolder"
    hide-details="auto"
    outlined
    :required="required"
    :rules="rules.textRules"
    :disabled="disabled"
    :readonly="readonly"
  >
  </v-textarea>

  <v-dialog v-else-if="inputType == 'monthPicker'" ref="monthModal" v-model="menu" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        color="primary accent-4"
        persistent-placeholder
        :label="label"
        :placeholder="placeHolder"
        :append-icon="icons.mdiCalendar"
        :disabled="disabled"
        :value="selectMonth"
        outlined
        hide-details="auto"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :rules="rules.dateRules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectMonth"
      color="primary accent-4"
      @input="$emit('pickerInput', $event)"
      type="month"
      no-title
      scrollable
      :first-day-of-week="0"
      :day-format="$Formatter.dayFormat"
      locale="zh-hk"
      :disabled="disabled"
      :min="minDay"
      :max="maxDay"
    >
      <v-btn
        text
        small
        color="error"
        v-if="showClearBtn"
        @click="
          $refs.monthModal.save('')
          $emit('update:inputValue', '')
          $emit('monthDialog', 'clear')
        "
      >
        清除
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        small
        color="primary"
        @click="
          menu = false
          selectMonth = saveSelectMonth
          $emit('monthDialog', 'cancel')
        "
      >
        取消
      </v-btn>
      <v-btn
        text
        small
        color="success"
        @click="
          $refs.monthModal.save(selectMonth)
          $emit('update:inputValue', selectMonth)
          $emit('monthDialog', 'confirm')
        "
      >
        確定
      </v-btn>
    </v-date-picker>
  </v-dialog>

  <v-dialog v-else-if="inputType == 'monthRangePicker'" ref="monthRangeModal" v-model="menu" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        color="primary accent-4"
        persistent-placeholder
        :label="label"
        :placeholder="placeHolder"
        :append-icon="icons.mdiCalendar"
        :disabled="disabled"
        :value="displayCustomMonthRange(selectMonthRange)"
        outlined
        hide-details="auto"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :rules="rules.dateRangeRules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectMonthRange"
      color="primary accent-4"
      @input="$emit('pickerInput', $func.orderMonthArrInput($event))"
      type="month"
      range
      no-title
      scrollable
      :first-day-of-week="0"
      :day-format="$Formatter.dayFormat"
      locale="zh-hk"
      :disabled="disabled"
      :min="minDay"
      :max="maxDay"
    >
      <v-btn
        text
        small
        color="error"
        v-if="showClearBtn"
        @click="
          $refs.monthRangeModal.save([])
          $emit('update:inputValue', $func.orderMonthArrInput([]))
          $emit('monthRangeDialog', 'clear')
        "
      >
        清除
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        small
        color="primary"
        @click="
          menu = false
          selectMonthRange = saveSelectMonthRange
          $emit('monthRangeDialog', 'cancel')
        "
      >
        取消
      </v-btn>
      <v-btn
        text
        small
        color="success"
        @click="
          $refs.monthRangeModal.save(selectMonthRange)
          $emit('update:inputValue', $func.orderMonthArrInput(selectMonthRange))
          $emit('monthRangeDialog', 'confirm')
        "
      >
        確定
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import CustomDigitalTimepicker from '../CustomDigitalTimepicker.vue'

export default {
  name: 'FormControl',
  components: { CustomDigitalTimepicker },
  props: {
    placeHolder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputNumberOnly: {
      type: Boolean,
      default: false,
    },
    inputFloatingOnly: {
      type: Boolean,
      default: false,
    },
    inputAlphaNumericOnly: {
      type: Boolean,
      default: false,
    },
    validateFloating: {
      type: Boolean,
      default: false,
    },
    validateNumber: {
      type: Boolean,
      default: false,
    },
    customChecking: {
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    minDay: {
      type: String,
      default: '',
    },
    maxDay: {
      type: String,
      default: '',
    },
    showClearBtn: {
      type: Boolean,
      default: true,
    },
    inputValue: {},
  },

  setup() {
    return {
      icons: {
        mdiCalendar,
      },
    }
  },
  data() {
    return {
      menu: false,
      rules: {
        textRules: [
          ...this.customChecking,
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        numberRules: [
          ...this.customChecking,
          v => {
            return !this.validateNumber || this.$validate.isNumber(v)
          },
          v => {
            return !this.validateFloating || this.$validate.isFloating(v)
          },
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        phoneRules: [
          ...this.customChecking,
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        emailRules: [
          ...this.customChecking,
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
          v => {
            return this.$validate.emailValid(v)
          },
        ],
        dateRules: [
          ...this.customChecking,
          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
        dateRangeRules: [
          ...this.customChecking,

          v => {
            return this.required ? this.$validate.required(v) || '此位置不能留空' : true
          },
        ],
      },
      inputTime: '',
      selectDateRange: [],
      saveSelectDateRange: [],
      selectDate: '',
      saveSelectDate: '',
      selectMonthRange: [],
      saveSelectMonthRange: [],
      selectMonth: '',
      saveSelectMonth: '',
    }
  },
  watch: {
    inputValue: {
      handler(val) {
        if (this.inputType === 'timePicker') {
          this.inputTime = val
        } else if (this.inputType === 'dateRangePicker') {
          if (this.$validate.DataValid(val)) {
            if (Array.isArray(val)) {
              this.selectDateRange = val
              this.saveSelectDateRange = val
            } else {
              this.selectDateRange = [val]
              this.saveSelectDateRange = [val]
            }
          } else {
            this.selectDateRange = []
            this.saveSelectDateRange = []
          }
        } else if (this.inputType === 'datePicker') {
          if (this.$validate.DataValid(val)) {
            this.selectDate = val
            this.saveSelectDate = val
          } else {
            this.selectDate = ''
            this.saveSelectDate = ''
          }
        } else if (this.inputType === 'monthRangePicker') {
          if (this.$validate.DataValid(val)) {
            if (Array.isArray(val)) {
              this.selectMonthRange = val
              this.saveSelectMonthRange = val
            } else {
              this.selectMonthRange = [val]
              this.saveSelectMonthRange = [val]
            }
          } else {
            this.selectMonthRange = []
            this.saveSelectMonthRange = []
          }
        } else if (this.inputType === 'monthPicker') {
          if (this.$validate.DataValid(val)) {
            this.selectMonth = val
            this.saveSelectMonth = val
          } else {
            this.selectMonth = ''
            this.saveSelectMonth = ''
          }
        }
      },
      immediate: true,
    },
    inputTime: {
      handler(val) {
        this.$emit('update:inputValue', val)
      },
      immediate: true,
    },
  },
  methods: {
    formatInput(event) {
      if (this.inputNumberOnly) {
        return this.$format.number(event)
      } else if (this.inputFloatingOnly) {
        return this.$format.floating(event)
      }
    },
    displayCustomDateRange(date) {
      if (date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          return `${date[1]} (${this.$Formatter.displayWeekday(date[1])}) ~ ${
            date[0]
          } (${this.$Formatter.displayWeekday(date[0])})`
        } else {
          return `${date[0]} (${this.$Formatter.displayWeekday(date[0])}) ~ ${
            date[1]
          } (${this.$Formatter.displayWeekday(date[1])})`
        }
      } else if (date.length === 1) {
        return `${date[0]} (${this.$Formatter.displayWeekday(date[0])})`
      }

      return ''
    },
    displayCustomMonthRange(yearMonth) {
      if (yearMonth.length === 2) {
        if (yearMonth[0] > yearMonth[1]) {
          return `${yearMonth[1]} ~ ${yearMonth[0]}`
        } else {
          return `${yearMonth[0]} ~ ${yearMonth[1]}`
        }
      } else if (yearMonth.length === 1) {
        return `${yearMonth[0]}`
      }

      return ''
    },
  },
}
</script>
